.text-decoration-indigo-500 {
    text-decoration-line: line-through;
    text-decoration-color: #6366f1; /* Este es el color para índigo-500 en Tailwind */
    text-decoration-thickness: 2px; /* Ajusta el grosor de la línea de tachado */
}

/* Estilos base para la imagen del carrusel */
.carousel-image {
    position: relative;
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease-in-out; /* Transición suave */
}

/* Estilos para las animaciones de las imágenes */
@keyframes slideInLeft {
    from {
        transform: translateX(90%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(-90%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Animaciones de entrada de la imagen */
.slide-in-left {
    animation: slideInLeft 0.3s forwards;
}

.slide-in-right {
    animation: slideInRight 0.3s forwards;
}

.text-justify {
    text-align: justify;
}

.owl-item.active {
    height: auto;
}

/* Journey expandir carrusel*/
.journey-override {
    .owl-carousel {
        height: 100%;

        .owl-stage-outer {
            height: 100%;

            div {
                height: 100% !important;
            }
        }
    }
}