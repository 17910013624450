/* Tippy */

.tippy-arrow {
    width: 1rem;
    height: 1rem;
    color: #fbfdfe;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip .tippy-arrow {
    border-bottom: 7px solid red;
    /* your color here */
}

.tippy-box {
    position: relative;
    background-color: #fbfdfe;
    color: #141926;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    border: 1px solid #e2e8f0;
    transition-property: transform, visibility, opacity;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08),
        0 4px 6px -2px rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
