
[contenteditable="true"]:empty:before {
    content: attr(data-placeholder);
    color:rgb(148, 163, 184); /* Cambia el color que desees */
}

.editable {
    width: 300px;
    max-height: 100px;
    padding: 5px;
    overflow: auto;
    font-family: sans-serif;
    color: #334155;
    white-space: pre-wrap;
    line-break: normal;
    box-sizing: border-box;
    outline: none;
    border: none;
    --tw-ring-color: none !important;
    box-shadow: none !important;
}

.editable[contenteditable="true"]:focus {
    outline: none;
    border: none;
    --tw-ring-color: none !important;
    box-shadow: none !important;
}

.editable[contenteditable="true"]:empty:not(:focus):before {
    font-family: sans-serif;
    color: rgb(100 116 139 / var(--tw-text-opacity));
    pointer-events: none;
}

.comment-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100%;
}

.form-input.editable {
    flex: 1;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px 10px;
}

.send-btn {
    background-color: #1877f2;
    /* Color azul de Facebook */
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
    /* Espacio entre el área de texto y el botón */
}

.send-btn[disabled] {
    background-color: #b0c0d4;
    /* Gris desactivado */
    cursor: not-allowed;
}

.example-list {
    width: 500px;
    max-width: 100%;
    border: solid 1px #ccc;
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;
}

.example-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
}

.cdk-drag-preview {
    display: inline-table;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    @apply text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200 w-full;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
    border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.disabled-row {
    @apply pointer-events-none bg-slate-100;
}

.disabled-button {
    @apply pointer-events-none bg-slate-100;
}

/* Image cropper image center fix */
.ngx-ic-source-image {
    margin: auto !important;
}

/* Image cropper outline wider fix */
.ngx-ic-overlay,
.ngx-ic-cropper {
    outline: none !important;
}

.disabled-input {
    @apply pointer-events-none bg-slate-100 border-slate-200 shadow rounded text-slate-400;
}

.disabled-input {
    @apply pointer-events-none bg-slate-100 border-slate-200 shadow rounded text-slate-400;
}

.animate__evenFaster {
    --animate-duration: 0.3s;
}

.clean-input {
    @apply bg-transparent p-0 appearance-none border-0 ring-0 outline-none focus:ring-0 focus:outline-none;
}

body .shepherd-modal-overlay-container {
    @apply z-[99999997];
}

body .shepherd-element.custom-tour {
    @apply rounded-md shadow-lg border border-indigo-500 z-[99999999] overflow-hidden max-xs:max-w-[90%];

    &.shepherd-has-title {
        .shepherd-content {
            .shepherd-cancel-icon {
                @apply -mt-2;
            }

            .shepherd-header {
                @apply bg-transparent rounded-none pb-0;

                .shepherd-title {
                    @apply text-base font-semibold text-indigo-500;
                }
            }

            .shepherd-text {
                @apply text-base p-4;

                a,
                a:visited,
                a:active {
                    @apply border-b border-dotted border-indigo-500 text-indigo-500 no-underline hover:border-solid;
                }
            }

            .shepherd-footer {
                @apply rounded-none px-4 py-2;

                .shepherd-progress {
                    @apply text-slate-600 text-sm font-medium self-center justify-self-start text-center mr-auto;
                }

                .shepherd-button {
                    &:not(.shepherd-button-secondary) {
                        @apply uisr-primary-button px-3 py-2;
                    }

                    &.shepherd-button-secondary {
                        @apply uisr-secondary-button px-3 py-2;
                    }
                }
            }
        }
    }

    .shepherd-arrow {
        @apply border-0 h-auto w-auto before:hidden;
    }

    &[data-popper-placement^="top"] .shepherd-arrow,
    &.shepherd-pinned-top .shepherd-arrow {
        bottom: -35px;
    }

    &[data-popper-placement^="top"] .shepherd-arrow:after,
    &.shepherd-pinned-top .shepherd-arrow:after {
        transform: rotate(270deg);
    }

    &[data-popper-placement^="bottom"] .shepherd-arrow {
        top: -35px;
    }

    &[data-popper-placement^="bottom"] .shepherd-arrow:after {
        transform: rotate(90deg);
    }

    &[data-popper-placement^="left"] .shepherd-arrow,
    &.shepherd-pinned-left .shepherd-arrow {
        right: -35px;
    }

    &[data-popper-placement^="left"] .shepherd-arrow:after,
    &.shepherd-pinned-left .shepherd-arrow:after {
        transform: rotate(180deg);
    }

    &[data-popper-placement^="right"] .shepherd-arrow,
    &.shepherd-pinned-right .shepherd-arrow {
        left: -35px;
    }
}

.owl-theme .owl-dots .owl-dot span {
    width: 0.75rem;
    height: 0.75rem;
    background: theme("colors.indigo.200");
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: theme("colors.indigo.500");
}

/* Estilos actividad de historial cambios */

.history-change-table {
    td {
        max-width: 250px;
        div {
            white-space: break-spaces;
        }
    }
}

.cdk-drop-list-dragging {
    @apply ring-2 ring-indigo-500 shadow-md bg-indigo-50;
}
