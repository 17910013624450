html {
    --calc-font-size: calc(
        16px + (32 - 16) * ((100vw - 1920px) / (3840 - 1920))
    );
    font-size: clamp(16px, var(--calc-font-size), 400px);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Estilo del scrollbar */
::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: theme("colors.gray.50");
    // box-shadow: theme('colors.gray.100') 0px 0px 4px 1px inset;
}

/* Thumb */
::-webkit-scrollbar-thumb {
    background: theme("colors.gray.300");
    // border: 1px solid theme('colors.gray.50');
    // box-sizing: border-box;
}

/* Al pasar el mouse por encima del thumb */
::-webkit-scrollbar-thumb:hover {
    background: theme("colors.gray.300");
}

.app-name {
    background: linear-gradient(
        45deg,
        rgba(79, 72, 237, 1) 0%,
        rgba(79, 72, 237, 1) 35%,
        rgba(0, 212, 255, 1) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.custom-drag-placeholder {
    @apply absolute;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect {
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000;

    &::placeholder {
        color: rgb(148 163 184 / var(--tw-placeholder-opacity)) !important;
        /*color:var(--placeholder-text) !important;*/
        //color: #9ca3af;
        //color: #6b7280;
    }

    &.ng-invalid.ng-touched:not(.ng-select-opened) {
        @apply border-rose-300 #{!important};
    }
}