/* overlay.component.css */
.overlay {
    /* Estos son valores fijos, los mismos para todos los tamaños de pantalla */
    position: absolute;
    right: 0;
    transition: background-color 0.3s ease;
    /* transición suave */
    background-color: rgba(0, 0, 0, 0.5);
    /* fondo oscuro y transparente */
    overflow-x: hidden;
    /* lg:overflow-x-hidden */
    overflow-y: auto;
    /* lg:overflow-y-auto */
    border-top: 1px solid #e1e4e8;
    /* border-t border-slate-200 */
    border-left: 1px solid #e1e4e8;
    /* lg:border-l border-slate-200 */
    height: 100svh;
    /* lg:h-[calc(100svh-64px)] */

    /* Valores por defecto */
    top: 64px;
    /* top-16 */
    width: 100%;
    /* width 100% por defecto para pantallas pequeñas */
}

/* sm:para pantallas ≥640px */
@media (min-width: 640px) {
    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}

/* md:para pantallas ≥768px */
@media (min-width: 768px) {
    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}

/* lg:para pantallas ≥1024px */
@media (min-width: 1024px) {
    .overlay {
        position: absolute;
        /* lg:absolute */
        border-top: 0;
        /* lg:border-t-0 */
        border-left: 1px solid #e1e4e8;
        /* lg:border-l border-slate-200 */
        width: 320px;
        /* lg:w-[320px] */
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}

/* xl:para pantallas ≥1280px */
@media (min-width: 1280px) {
    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}

/* 2xl:para pantallas ≥1536px */
@media (min-width: 1536px) {
    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}