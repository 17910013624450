/* NgSelect */
ng-select.select-excel {
    div.ng-select-container {
      border-style: none !important;
    }
  }
.ng-select.ng-select-override {
    .ng-select-container {
        --tw-border-opacity: 1 !important;
        border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
        line-height: 1.25rem !important;
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        padding-right: 0 !important;
        border-radius: 0.25rem !important;
        background: white !important;

        &:hover {
            --tw-border-opacity: 1 !important;
            border-color: rgb(
                203 213 225 / var(--tw-border-opacity)
            ) !important;
        }
    }

    &.iconLeft .ng-select-container {
        @apply pl-[2.375rem] #{!important};
    }

    &.iconRight .ng-select-container {
        @apply pr-[2.375rem] #{!important};
    }

    &.ng-select-focused {
        .ng-select-container {
            outline: 2px solid transparent;
            outline-offset: 2px;
            --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
            --tw-ring-offset-width: 0px;
            --tw-ring-offset-color: #fff;
            --tw-ring-color: #2563eb;
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
                var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
                calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
                var(--tw-shadow);
            border-color: #2563eb;
        }

        //.ng-dropdown-panel {
        //    outline: 2px solid transparent;
        //    outline-offset: 2px;
        //    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
        //    --tw-ring-offset-width: 0px;
        //    --tw-ring-offset-color: #fff;
        //    --tw-ring-color: #2563eb;
        //    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        //    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        //    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
        //    border-color: #2563eb;
        //}
    }

    .ng-value-container {
        padding: 0 !important;
        gap: 5px;
        height: 100%;
        position: relative;
        overflow: visible !important;

        .ng-value {
            margin: 0 !important;
            max-height: 1.25rem;
            overflow: hidden;
            display: flex !important;
            width: fit-content;

            .ng-value-icon {
                display: flex !important;
                align-items: center;
                justify-content: center;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }

    .ng-input {
        padding: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        top: 0 !important;
        bottom: 0 !important;

        input {
            height: 100%;
            @apply text-sm #{!important};

            &::placeholder {
                @apply text-sm #{!important};
            }
        }
    }

    .ng-value-label {
        @apply text-sm #{!important};
    }

    .ng-option-label {
        @apply text-sm #{!important};
    }

    .ng-placeholder {
        @apply text-sm text-slate-400 #{!important};
    }

    .ng-arrow-wrapper {
        width: 1.563rem !important;
        padding: 0 !important;

        .ng-arrow {
            border-width: 0.313rem 0.313rem 0.156rem !important;
        }
    }

    &.ng-select-opened {
        .ng-arrow {
            border-width: 0 0.313rem 0.313rem !important;
        }

        .ng-select-container {
            z-index: unset !important;
        }
    }

    .ng-clear-wrapper {
        width: 1.063rem !important;

        .ng-clear {
            font-size: 1.125rem !important;
        }
    }

    .ng-dropdown-panel {
        --tw-border-opacity: 1 !important;
        border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    }

    &.ng-select-single {
        .ng-value-container {
            .ng-value {
                .ng-value-icon {
                    display: none !important;
                }
            }
        }

        .ng-input {
            /*max-width: 100% !important;
            height: 100% !important;
            top: 0 !important;
            left: 0 !important;
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;*/
        }

        .ng-select-container {
            height: 2.375rem;
        }
    }

    &.ng-select-multiple {
        .ng-value {
            width: auto !important;
        }

        .ng-placeholder {
            padding: 0 !important;
            top: auto !important;
        }
    }

    &.ng-invalid.ng-touched:not(.ng-select-opened) {
        .ng-select-container {
            @apply border-rose-300 #{!important};
        }
    }

    &.ng-select-disabled {
        cursor: not-allowed;

        .ng-select-container {
            background-color: #f1f5f9;
            pointer-events: none;
        }
    }

    &.labelTemplate {
        .ng-value {
            max-height: unset;
            width: 100%;
        }
    }

    &.ng-select-disabled {
        @apply bg-slate-100 #{!important};
    }

    &.invalid {
        .ng-select-container {
            @apply border-rose-300 #{!important};

            &:hover {
                --tw-border-opacity: 1 !important;
                border-color: rgb(
                    203 213 225 / var(--tw-border-opacity)
                ) !important;
            }
        }
    }
}
