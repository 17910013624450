.e-schedule-toolbar-container {
    display: none;
}

.e-schedule .e-agenda-view .e-appointment {
    border-left-width: 0.5rem;

    @apply font-inter py-0.5 cursor-pointer #{!important};

    .e-subject {
        @apply text-xs font-semibold #{!important};
    }

    .e-date-time {
        @apply text-xs #{!important};
    }
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    @apply font-inter px-2 py-0.5 cursor-pointer rounded overflow-hidden #{!important};

    .e-appointment-details {
        @apply p-0 #{!important};
    }

    .e-subject {
        @apply text-xs font-semibold truncate #{!important};
    }

    .e-time {
        @apply text-xs #{!important};
    }
}

.e-schedule .e-month-view .e-appointment,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment {
    @apply font-inter px-2 py-0.5 cursor-pointer rounded overflow-hidden flex items-center #{!important};

    .e-appointment-details {
        @apply flex items-center gap-1 #{!important};

        .e-time {
            @apply text-xs p-0 m-0 leading-[normal] #{!important};
        }

        .e-subject {
            @apply text-xs p-0 m-0 leading-[normal] font-semibold truncate #{!important};
        }

        .e-indicator {
            @apply text-xs p-0 m-0 leading-[normal] #{!important};
        }
    }
}

.e-schedule .e-month-view .e-work-cells:not(.e-selected-cell) {
    @apply bg-[#e8fde7] #{!important};

    &:hover {
        @apply bg-[#f3f4f6] #{!important};
    }
}

.e-schedule .e-month-view .e-work-days:not(.e-selected-cell) {
    @apply bg-white #{!important};
}

.e-schedule .e-month-view .e-current-date:not(.e-selected-cell) {
    @apply bg-[#eae9ff] #{!important};
}

.e-schedule .e-month-view .e-other-month:not(.e-selected-cell) {
    background-image: repeating-linear-gradient(
        45deg,
        #f8fafc,
        #f8fafc 4px,
        #e2e8f0 6px,
        #e2e8f0 6px
    ) !important;

    @apply bg-slate-50 #{!important};
}

.e-date-header.e-navigate {
    pointer-events: none;

    @apply w-full m-0 p-0 #{!important};

    button {
        pointer-events: all;
    }
}

.e-current-date .e-date-header.e-navigate {
    background: transparent !important;

    button {
        @apply bg-indigo-500 text-white #{!important};
    }
}

.e-date-header-wrap {
    @apply border-r-0 #{!important};
}

.e-schedule .e-vertical-view .e-date-header-container {
    @apply p-0 overflow-y-scroll #{!important};
}

.e-schedule .e-vertical-view .e-date-header-container:has(.e-all-day-scroll) {
    @apply p-0 overflow-y-auto #{!important};
}

.e-all-day-scroll {
    @apply overflow-y-scroll #{!important};
}

.e-schedule .e-vertical-view .e-all-day-row {
    .e-all-day-cells {
        //@apply border-t #{!important};
    }
}

.e-schedule .e-vertical-view .e-header-cells {
    @apply font-inter border-l-0 border-r-0 border-b #{!important};

    .e-header-day {
        @apply text-slate-500 text-sm #{!important};
    }

    .e-header-date.e-navigate {
        @apply text-slate-500 text-sm #{!important};
    }

    &.e-current-day {
        .e-header-day,
        .e-header-date.e-navigate {
            @apply text-indigo-500 #{!important};
        }
    }
}

.e-schedule .e-vertical-view .e-header-cells.e-disable-dates {
    @apply border-r #{!important};
}

.e-schedule .e-vertical-view .e-time-slots {
    span {
        @apply font-inter text-slate-600 #{!important};
    }
}

.e-schedule .e-month-view .e-date-header-container {
    @apply border-b #{!important};
}

.e-schedule .e-month-view .e-header-cells {
    @apply font-inter px-1 py-3 border-l-0 border-r-0 border-b-0 #{!important};

    span {
        @apply text-slate-500 text-sm font-medium text-center capitalize #{!important};
    }
}

.e-schedule .e-agenda-view .e-day-date-header {
    &.e-current-day {
        .e-m-date,
        .e-m-day {
            @apply text-indigo-500 font-medium #{!important};
        }
    }

    .e-m-date {
        @apply font-inter text-slate-500 text-sm #{!important};
    }

    .e-m-day {
        @apply font-inter text-slate-500 text-sm #{!important};
    }
}

.e-schedule .e-agenda-view .e-appointment {
    @apply font-inter px-2 py-0.5 cursor-pointer rounded overflow-hidden #{!important};

    .e-subject {
        color: inherit !important;
        @apply text-xs #{!important};
    }

    .e-date-time {
        color: inherit !important;
        @apply text-xs #{!important};
    }
}

.e-selected-cell {
    box-shadow: inset 1px 1px 0px 0px #6366f152,
        inset -1px -1px 0px 0px #6366f152;
    @apply bg-indigo-100 #{!important};
}

.e-quick-popup-wrapper .e-event-popup {
    .e-popup-header,
    .e-popup-content,
    .e-popup-footer {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.e-more-indicator {
    @apply font-inter text-2xs w-auto text-slate-500 font-medium whitespace-nowrap text-center py-px px-0.5 sm:px-2 ml-px my-px border border-slate-200 rounded truncate #{!important};
}

.e-icons.e-recurrence-icon {
    color: inherit !important;

    @apply text-xs p-0 m-0 leading-[normal] #{!important};
}

.e-subject-wrap {
    @apply items-center gap-2 #{!important};
}

.e-agenda-item {
    @apply pr-2 #{!important};
}

/*.e-appointment-expand, .e-appointment-collapse {
    @apply w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer grid place-content-center p-1 rounded-full bg-white border border-slate-200 hover:border-slate-300 shadow-sm #{!important};
}*/

.e-appointment-expand,
.e-appointment-collapse {
    @apply cursor-pointer grid place-content-center p-1 rounded-full bg-white border border-slate-200 hover:border-slate-300 shadow-sm #{!important};
}

.e-spinner-pane {
    display: none !important;
}
